import React from 'react'

const AddressCard = () => {
  return (
    <div>
      <div className="space-y-3">
        <p className="font-semibold">Raam Kapoor</p>
        <p>266, priyank nafar, vijapur road, 413004</p>
        <div className="space-y-1">
            <p className="font-semibold">Phone Number</p>
            <p>875694123</p>
        </div>
      </div>
    </div>
  )
}

export default AddressCard
