export const mainCarouselData=[
    {
        image: "https://www.ethnicplus.in/media/mageplaza/bannerslider/banner/image/1/0/10_5.jpg",
        path: "/women/clothing/lengha_choli"
    },
    {
        image: "https://www.ethnicplus.in/media/mageplaza/bannerslider/banner/image/1/2/12_4.jpg",
        path: "/women/clothing/women_dress"
    },
    {
        image: "https://www.ethnicplus.in/media/mageplaza/bannerslider/banner/image/9/_/9_8.jpg",
        path: "/women/clothing/women_dress"
    },
    {
        image: "https://www.ethnicplus.in/media/mageplaza/bannerslider/banner/image/1/1/11_4.jpg",
        path: "/women/clothing/women_saree"
    },
]